import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import styles from './404.module.css'

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page Not found" />
    <div className={styles.container}>
      <h1>Ooops!</h1>
      <p>This page doesn&#39;t seem to exist anymore... the sadness.</p>
    </div>
  </Layout>
)

export default NotFoundPage
